
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import vSelect from "vue-select";
import OpenIndicator from "@/assets/svg/heroicons/selector.svg";
import { Action, Getter } from "vuex-class";
import type { CreateTimelinePayload } from "@/store/modules/timeline.store";
import { FormError } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import Mention from "@tiptap/extension-mention";
import suggestion from "@/components/mentions/suggestion";
import { HasFormErrors } from "@/mixins/has-form-errors";

vSelect.props.components.default = () => ({ OpenIndicator });

@Component({
    components: {
        EditorContent,
        vSelect,
        FormError,
    },
})
export default class TimelineCreateEntry extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Action("timeline/create") createTimelineEntry!: (payload: CreateTimelinePayload) => Promise<TimelineItem>;

    @Prop({ default: true }) canSetType!: boolean;
    @Prop({ default: false }) canMention!: boolean;
    @Prop({
        default: () => {
            return { label: "Notitie", value: "NOTE" };
        },
    })
    defaultType!: any;

    editor: Editor | null = null;

    entryType: { label: string; value: "NOTE" | "EMAIL" | "PHONE" | "SELLER_NOTE" } = this.defaultType;

    entryOptions = [
        { label: "Notitie", value: "NOTE" },
        { label: "Email", value: "EMAIL" },
        { label: "Gesprek", value: "PHONE" },
    ];

    loading = false;

    get validHtml() {
        if (!this.editor) {
            return false;
        }

        return !this.editor.isEmpty;
    }

    async mounted() {
        this.editor = new Editor({
            content: "",
            editorProps: {
                attributes: {
                    class: "prose caret-secondary-500 max-w-none border border border-gray-50 focus:outline-none bg-white min-h-[120px] transition-colors rounded-[16px] pt-[8px] pb-[48px] px-[12px]",
                },
            },
            extensions: [
                StarterKit,
                Underline,
                Placeholder.configure({
                    placeholder: ({ node }) => {
                        if (this.entryType.value === "EMAIL") {
                            return "Voeg een e-mail toe...";
                        }
                        if (this.entryType.value === "PHONE") {
                            return "Voeg een telefoongesprek toe...";
                        }
                        return "Voeg een notitie toe...";
                    },
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: "mention",
                    },
                    renderLabel({ options, node }) {
                        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
                    },
                    suggestion,
                }),
            ],
        });
    }

    beforeDestroy() {
        if (!this.editor) {
            return;
        }

        this.editor.destroy();
    }

    async submit() {
        if (!this.validHtml) {
            return;
        }

        this.loading = true;

        try {
            await this.createTimelineEntry({ activity_id: this.activity.id, type: this.entryType.value, body: { note: this.editor?.getHTML() ?? "" } });

            if (!this.editor) {
                return;
            }

            this.editor.commands.clearContent(true);
        } catch (e) {
            this.errorResponse = formatErrors(e);
        }

        this.loading = false;
    }
}
